export const CONTACT = 'contact'
export const SURVEY = 'survey'
export const CLOSED = 'closed'
export const THANKS = 'thanks'

export const SMALL_HEADER_PAGES = {
  INFLUENCERS: 'influencers',
  TERMS: 'terms',
  ONE_BILLION: 'onebillion',
  PARTNERSHIP: 'partnership',
}

export const ROUTE = {
  HOME_PAGE: '/',
  HOME_PAGE_KR: '/kr',
  MY_PAGE: '/mypage',
  LOGIN_PAGE: '/login',
}

export const dbFormType = {
  enterToWin: {
    contact: 'enterToWinContact',
    survey: 'enterToWinSurvey',
  },
  askSpurs: {
    contact: 'askSpursContact',
  },
}

export const sfFormType = {
  enterToWin: { aia_enter_to_win_2023_survey: true },
}
