import React from 'react'
import ReactDOM from 'react-dom'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

import styles from './styles'

const Modal = React.forwardRef(
  ({ onClose, children, customModalStyles }, ref) => {
    const modal = (
      <React.Fragment ref={ref}>
        <div sx={styles.modalOverlay} />
        <div sx={{ ...styles.modalWrapper, ...customModalStyles }}>
          <div sx={styles.modalHeader}>
            <button sx={styles.modalCloseButton} onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div sx={styles.modalText}>{children}</div>
        </div>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {children !== null && children !== undefined
          ? ReactDOM.createPortal(modal, document.body)
          : null}
      </React.Fragment>
    )
  },
)

export default Modal
